import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Button from 'mfcl/Button'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Buttons make common actions more obvious and help users more easily perform them. Buttons use labels and sometimes icons to communicate the action that will occur when the user touches them.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "button-types"
    }}>{`Button Types`}</h2>
    <Playground __position={1} __code={'<Button>Primary</Button>\n<br />\n<br />\n<Button btnType=\"secondary\">Secondary</Button>\n<br />\n<br />\n<Button btnType=\"tertiary\">Tertiary</Button>\n<br />\n<br />\n<Button btnType=\"link\">Text Link</Button>\n<br />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button mdxType="Button">Primary</Button>
  <br />
  <br />
  <Button btnType="secondary" mdxType="Button">Secondary</Button>
  <br />
  <br />
  <Button btnType="tertiary" mdxType="Button">Tertiary</Button>
  <br />
  <br />
  <Button btnType="link" mdxType="Button">Text Link</Button>
  <br />
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <Playground __position={2} __code={'<Button btnType=\"primary\" size=\"sm\">\n  Small\n</Button>\n<span style={{ margin: \'6px\' }} />\n<Button btnType=\"primary\" size=\"md\">\n  Medium\n</Button>\n<span style={{ margin: \'6px\' }} />\n<Button btnType=\"primary\" size=\"lg\">\n  Large\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button btnType="primary" size="sm" mdxType="Button">
    Small
  </Button>
  <span style={{
        margin: '6px'
      }} />
  <Button btnType="primary" size="md" mdxType="Button">
    Medium
  </Button>
  <span style={{
        margin: '6px'
      }} />
  <Button btnType="primary" size="lg" mdxType="Button">
    Large
  </Button>
    </Playground>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <Playground __position={3} __code={'<Button size=\"lg\" disabled btnType=\"primary\">\n  Disabled\n</Button>\n<br />\n<br />\n<Button disabled btnType=\"link\">\n  Text Disabled\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button size="lg" disabled btnType="primary" mdxType="Button">
    Disabled
  </Button>
  <br />
  <br />
  <Button disabled btnType="link" mdxType="Button">
    Text Disabled
  </Button>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      